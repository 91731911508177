.my-card {
  overflow: hidden;
  position: relative;
  border: none !important;

  .card-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: transform 0.5s ease;
    border-radius: 0 !important;
  }

  .card-img:hover {
    transform: scale(1.1);
  }

  .card-body {
    padding: 1rem;
    background: white;
    z-index: 1;
    font-size: 0.8rem;
    height: 240px;
  }

  .card-body {
    scrollbar-width: thin;
    scrollbar-color: var(--mainColor) #f1f1f1;
    font-size: 1rem;
  }

  .card-title {
    font-size: 1.2rem;
    color: var(--mainColor) !important;
    font-weight: bold !important;
  }

  .card-text ul li {
    font-size: 0.8rem;
    padding: 5px;
    color: #6c757d;
  }
}

.icon-container {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 2;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icon {
  font-size: 18px;
  color: var(--mainColor);
}

@media (max-width: 991px) {
  .card-body {
    overflow: auto;
  }

  .card-title {
    font-size: 0.9rem !important;
  }

  .card-text {
    font-size: 0.6rem;
  }

  .card-img:hover {
    transform: scale(1);
  }
}

@media (max-width: 765px) {
  .card-title {
    font-size: 1rem;
  }

  .card-text {
    font-size: 0.8rem;
  }
}
