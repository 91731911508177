.cardsHome {
  background-color: #b79a6d27;

  padding: 10px;
}
.homeServices {
  margin: 30px;
  padding: 30px;
  width: 50%;
  margin: auto;
}

@media (max-width: 992px) {
  .homeServices {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .homeServices {
    width: 90%;
  }

  .plane img {
    width: 50%;
    top: 48% !important;
    left: 0;
  }
}
@media (max-width: 615px) {
  .homeServices {
    width: 90%;
  }
}
