.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-content {
  flex: 1;
}

.footer {
  background-color: #292929;
  color: #fff;
  width: 100%;
}
